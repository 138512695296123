// vant ui 组件库注册
import {
  Button,
  Icon,
  Popup,
  Loading
} from 'vant';

const componentList = [
  Button,
  Icon,
  Popup,
  Loading
];

export function registerVantComp (app) {
  componentList.forEach((comp) => {
    app.use(comp);
  });
}

