/*
 * @Author: luoyichen
 * @Date: 2023-08-14 15:41:50
 * @LastEditors: luoyichen
 * @LastEditTime: 2023-08-14 15:41:51
 * @FilePath: /utils/set-page-title.js
 * @Description:
 */
import defaultSettings from '@/settings';

const title = defaultSettings.title || '';

export default function setPageTitle (pageTitle) {
  if (!title && !pageTitle) return;
  // 设置页面 title
  window.document.title = title ? `${pageTitle} | ${title}` : `${pageTitle}`;
}
