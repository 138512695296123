import {createWebHistory, createRouter} from 'vue-router';
import routes from './routes';
import setPageTitle from '@/utils/set-page-title.js';

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // 设置页面 title
  setPageTitle(to.meta.title);
  next();
});

export default router;
