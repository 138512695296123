// import Layout from "@/layout";

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/index/index.vue'),
    meta: {title: 'Math-X'}
  }
];
export default routes;
