var u = navigator.userAgent;
window.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
window.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
window.jsbridge = function (callback) {
  if (window.WebViewJavascriptBridge) {
    console.log('存在WebViewJavascriptBridge，端内注入的对象', window.WebViewJavascriptBridge);
    return callback(window.WebViewJavascriptBridge);
  } else {
    document.addEventListener(
      'WebViewJavascriptBridgeReady',
      function () {
        console.log('监听到ready，端内注入的对象', window.WebViewJavascriptBridge);
        callback(window.WebViewJavascriptBridge);
      },
      false
    );
  }
};

window.jsbridge(function (bridge) {
  bridge?.init?.(function (message, responseCallback) {
    var data = {};
    responseCallback(data);
  });
});
/**
 * @param  {option对象}
 * method: 调用的方法
 * data: 传递的参数
 * callback: 回掉方法
 * @return {[type]}
 */
function bridgeCallHandler (method, data = {}, callback = (() => {})) {
  window.jsbridge(function (bridge) {
    console.log('callHandler方法调用, 调用参数', method, data, callback);
    console.log('callHandler', bridge?.callHandler);
      bridge?.callHandler(
        method,
        data,
        callback
      );
  });
}

/**
 * @param  {option对象}
 * method: 调用的方法
 * data: 传递的参数
 * callback: 回掉方法
 * @return {[type]}
 */
function bridgeSend (method, data = {}, callback = (() => {})) {
  window.jsbridge(function (bridge) {
    console.log('send方法调用', '调用参数', data);
    console.log('WebViewJavascriptBridge', bridge);
    console.log('send', bridge?.send);
    console.log('doSend:', bridge?.doSend);
    bridge?.send?.(
      data,
      callback
    );
  });
}
// 原生调js
let registerHandler = function (method, callback) {
  window.jsbridge(function (bridge) {
    bridge.registerHandler(
      method,
      function (data, responseCallback) {
        if (callback) {
          callback(data);
        }
        responseCallback(data || {});
      }
    );
  });
};
export default {
  registerHandler,
  bridgeCallHandler,
  bridgeSend
};
