import {createApp} from 'vue';
import router from './router';
import App from './App.vue';
import jsBridge from '../../utils/jsbridage';
// 引入全局样式
import 'normalize.css/normalize.css';
// 全局样式
import '@/styles/index.less';
// 引入组件样式
import 'vant/lib/index.css';
// 调试开启
import VConsole from 'vconsole';
if (process.env.NODE_ENV!=='production') {
  const vConsole = new VConsole();
  console.log('主入口:', vConsole);
}

const app = createApp(App);

// 按需注册 vant 组件
import {registerVantComp} from '@/plugins/registerVant';
registerVantComp(app);

// svg-icon
import {registerSvgIconComp} from '@/plugins/registerSvgIcon';
registerSvgIconComp(app);

// bridge挂载到全局
// app.config.globalProperties.$jsBridge=jsBridge;
app.provide('$jsBridge', jsBridge);

app
  .use(router)
  .mount('#app');

